export const FETCH_SHIPPING_SUCCESS = "FETCH_SHIPPING_SUCCESS";

export const FETCH_SHIPPING_DETAILS_SUCCESS = "FETCH_SHIPPING_DETAILS_SUCCESS";

export const UPDATE_SHIPPING_DETAILS_SUCCESS =
    "UPDATE_SHIPPING_DETAILS_SUCCESS";

export const DELETE_SHIPPING_SUCCESS = "DELETE_SHIPPING_SUCCESS";

export const ADD_SHIPPING = "ADD_SHIPPING";

export const CLEAR_SHIPPING = "CLEAR_SHIPPING";

export const FORMAT_SHIPPING_SUCCESS = "FORMAT_SHIPPING_SUCCESS";
