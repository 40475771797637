import {
    cibBehance,
    cibCcAmex,
    cibCcApplePay,
    cibCcMastercard,
    cibCcPaypal,
    cibCcStripe,
    cibCcVisa,
    cibDribbble,
    cibFacebook,
    cibFlickr,
    cibGithub,
    cibGoogle,
    cibInstagram,
    cibLinkedin,
    cibPinterest,
    cibReddit,
    cibServerFault,
    cibStackoverflow,
    cibTumblr,
    cibTwitter,
    cibVimeo,
    cibVk,
    cibXing,
    cibYahoo,
    cibYoutube,
    cifBr,
    cifEs,
    cifFr,
    cifIn,
    cifPl,
    cifUs,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBan,
    cilBarcode,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCalendarCheck,
    cilChartLine,
    cilChartPie,
    cilCheck,
    cilCheckAlt,
    cilCheckCircle,
    cilChevronBottom,
    cilChevronTop,
    cilCloudDownload,
    cilCode,
    cilCommentSquare,
    cilContact,
    cilContrast,
    cilCursor,
    cilDescription,
    cilDollar,
    cilDrop,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilFile,
    cilGlobeAlt,
    cilGrid,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPlus,
    cilPuzzle,
    cilReload,
    cilRoom,
    cilSend,
    cilSettings,
    cilShieldAlt,
    cilShortText,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilSquare,
    cilStar,
    cilStorage,
    cilSun,
    cilTask,
    cilTruck,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilViewQuilt,
    cilX,
    cilXCircle,
} from "@coreui/icons";

export const iconsSet = Object.assign(
    {},
    {
        cilArrowBottom,
        cilArrowRight,
        cilArrowTop,
        cilBan,
        cilBarcode,
        cilBasket,
        cilBell,
        cilCalculator,
        cilCalendar,
        cilCalendarCheck,
        cilChartLine,
        cilChartPie,
        cilCheck,
        cilCheckAlt,
        cilCheckCircle,
        cilChevronBottom,
        cilChevronTop,
        cilCloudDownload,
        cilCode,
        cilCommentSquare,
        cilContact,
        cilContrast,
        cilCursor,
        cilDescription,
        cilDollar,
        cilDrop,
        cilEnvelopeClosed,
        cilEnvelopeOpen,
        cilEuro,
        cilFile,
        cilGlobeAlt,
        cilGrid,
        cilJustifyCenter,
        cilLaptop,
        cilLayers,
        cilLightbulb,
        cilList,
        cilLocationPin,
        cilLockLocked,
        cilMagnifyingGlass,
        cilMediaPlay,
        cilMenu,
        cilMoon,
        cilNotes,
        cilOptions,
        cilPencil,
        cilPeople,
        cilPlus,
        cilPuzzle,
        cilReload,
        cilRoom,
        cilSend,
        cilSettings,
        cilShieldAlt,
        cilShortText,
        cilSpeech,
        cilSpeedometer,
        cilSpreadsheet,
        cilSquare,
        cilStar,
        cilStorage,
        cilSun,
        cilTask,
        cilTruck,
        cilUser,
        cilUserFemale,
        cilUserFollow,
        cilViewQuilt,
        cilX,
        cilXCircle,
    },
    {
        cifBr,
        cifEs,
        cifFr,
        cifIn,
        cifPl,
        cifUs,
    },
    {
        cibBehance,
        cibCcAmex,
        cibCcApplePay,
        cibCcMastercard,
        cibCcPaypal,
        cibCcStripe,
        cibCcVisa,
        cibDribbble,
        cibFacebook,
        cibFlickr,
        cibGithub,
        cibGoogle,
        cibInstagram,
        cibLinkedin,
        cibPinterest,
        cibReddit,
        cibServerFault,
        cibStackoverflow,
        cibTumblr,
        cibTwitter,
        cibVimeo,
        cibVk,
        cibXing,
        cibYahoo,
        cibYoutube,
    }
);
